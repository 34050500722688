import React from 'react';

import Icon from './Icon';

export default function InfoIcon({ text = 'information' }: { text?: string; } = {}): JSX.Element {
  return (
    <Icon text={text}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="black" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.25 9.25H8C7.72386 9.25 7.5 9.02614 7.5 8.75V8.25C7.5 7.97386 7.72386 7.75 8 7.75H9.8C10.3247 7.75 10.75 8.17533 10.75 8.7V15.25H12C12.2761 15.25 12.5 15.4739 12.5 15.75V16.25C12.5 16.5261 12.2761 16.75 12 16.75H10.2C9.67533 16.75 9.25 16.3247 9.25 15.8V9.25Z"
        />
        <path d="M11.2552 4.58862C11.2552 5.28189 10.6932 5.8439 9.99991 5.8439C9.30664 5.8439 8.74463 5.28189 8.74463 4.58862C8.74463 3.89535 9.30664 3.33334 9.99991 3.33334C10.6932 3.33334 11.2552 3.89535 11.2552 4.58862Z" />
      </svg>
    </Icon>
  );
}
