import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useCallback, useMemo, useState } from 'react';
import { showModal } from 'shared/foreground/actions/modals';
import { getView } from 'shared/foreground/stateGetters';
import {
  addPinnedFeed,
  removeFeedIdsFromSidebar,
} from 'shared/foreground/stateUpdaters/persistentStateUpdaters/feed';
import { setFeedIdsToAddInFolder } from 'shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { RssFolder } from 'shared/types';

import Button from '../Button';
import ViewsIcon from '../icons/16StrokeViews';
import ChevronDownIcon from '../icons/ChevronDownSmallIcon';
import { modalId as addRssFolderModalId } from '../Modals/AddRssFolderModal';
import styles from './BulkFeedsViewsDropdown.module.css';
import { getSeparatorOption, getTitleOption } from './docOptions';
import { Dropdown, DropdownOptionType } from './Dropdown';

const Trigger = () => {
  return (
    <Button className={styles.manageViewsButton} variant="secondary">
      <ViewsIcon className={styles.viewsIcon} />
      Manage folders
      <ChevronDownIcon />
    </Button>
  );
};

export default React.memo(function BulkFeedsViewsDropdown({
  selectedFeedIds,
  rssFolders,
  associatedFolders,
}: {
  selectedFeedIds: string[];
  rssFolders: RssFolder[];
  associatedFolders: (RssFolder & { isAssociatedWithAllFeeds: boolean; })[];
}) {
  const [isOpen, setIsOpen] = useState(false);

  const TriggerElement =
    <DropdownMenu.Trigger>
      <Trigger />
    </DropdownMenu.Trigger>;
  const removeFeedFromFolder = useCallback(
    async (rssFolderId: string) => {
      removeFeedIdsFromSidebar({
        feedIds: selectedFeedIds,
        rssFolderId,
        options: { userInteraction: 'click' },
      });
    },
    [selectedFeedIds],
  );

  const addFeedToFolder = useCallback(
    (folder: RssFolder) => {
      for (const feedId of selectedFeedIds) {
        addPinnedFeed({
          rssSourceId: feedId,
          rssFolderId: folder.id,
          order: Infinity,
          options: { userInteraction: 'click' },
        });
      }
    },
    [selectedFeedIds],
  );

  const getFolderFilteredViewName = useCallback((folder: RssFolder) => {
    const folderFilteredView = getView(folder.filteredViewId);
    return folderFilteredView?.name || 'No name';
  }, []);

  const foldersOptions = useMemo(
    () =>
      rssFolders?.map((folder) => {
        const checked = Boolean(associatedFolders?.find((f) => f.id === folder.id));

        return {
          type: DropdownOptionType.Checkbox,
          name: getFolderFilteredViewName(folder),
          checked,
          setChecked: (isChecked: boolean) => {
            if (isChecked) {
              addFeedToFolder(folder);
            } else {
              removeFeedFromFolder(folder.id);
            }
          },
        };
      }) ?? [],
    [associatedFolders, rssFolders, addFeedToFolder, getFolderFilteredViewName, removeFeedFromFolder],
  );

  const options = useMemo(
    () => [
      getTitleOption(
        `Include documents from ${selectedFeedIds.length} selected feeds in the following folders`,
        styles.itemTitle,
      ),
      getSeparatorOption(),
      {
        type: DropdownOptionType.ScrollableItems,
        childOptions: foldersOptions,
      },
      getSeparatorOption(),
      {
        type: DropdownOptionType.Item,
        isCreate: true,
        name: 'Create a new folder from selected feeds',
        className: styles.createViewOption,
        onSelect: () => {
          setFeedIdsToAddInFolder(selectedFeedIds, { userInteraction: 'click' });
          showModal({ id: addRssFolderModalId }, { userInteraction: 'click' });
        },
      },
    ],
    [selectedFeedIds, foldersOptions],
  );

  return (
    <Dropdown
      trigger={TriggerElement}
      options={options}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      appendToDocumentBody
    />
  );
});
